.text-clamp-1 {
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
}

.text-clamp-2 {
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
}

.text-clamp-3 {
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
}

.text-gray-600-noim {
    color: #7E8299;
}

.relations-border {
    overflow: hidden;
    z-index: 1;

    &::after {
        z-index: -1;
        content: '';
        display: block;
        width: calc(100% + 80px);
        height: 2px;
        left: -25px;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        background: #7239EA;
    }
}

.relations-desc {
    position: absolute;
    bottom: 10px;
    left: 50%;
    right: 0;
    transform: translateX(-50%);
}

.field-head {
    display: flex;
    flex-direction: row;
    -webkit-box-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    align-items: center;

    .title {
        padding-right: 16px;
        color: rgb(102, 102, 135);
        font-weight: 600;
        font-size: 0.8875rem;
        line-height: 1.45;
        text-transform: uppercase;
    }

    .line {
        flex: 1 1 0%;
        align-self: center;
        border-top: 1px solid rgb(234, 234, 239);
    }

    .right {
        padding-left: 16px;
    }
}

.data-table-search {
    transition: all .3s !important;
    text-indent: 35px;
}

.modul-form {
    .selectedValues {
        max-height: 225px;
        overflow: auto;
        .value {
            border-bottom: 1px solid var(--bs-gray-200) !important;
            &:last-child {
                border-bottom: none !important;
            }
        }
    }
}

.object-cover {
    object-fit: cover;
}

.object-contain {
    object-fit: contain;
}


.module-media-card {
    background: #f2f3f5;
    height: 130px;
    position: relative;

    .empty-media {
        position: absolute;
        width: 100%;
        height: 100%;
    }

    figure {
        height: 110px;
        max-width: 100%;
        margin-bottom: 0;

        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }

    .actions {
        position: absolute;
        right: 10px;
        top: 10px;
        z-index: 999;
    }

}

.draggable-edit-media {
    figure {
        height: 140px;
        max-width: 100%;
        margin-bottom: 0;

        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }
}

.cursor-all {
    cursor: all-scroll;
}

.module-component-card {
    background: #f2f3f5;
    min-height: 130px;
    position: relative;

    .empty-component {
        position: absolute;
        width: 100%;
        height: 100%;
        min-height: 130px;
    }
}

.csdropzone {
    flex-wrap: wrap;
    gap: 10px;
    display: flex;
}
.csdropzone .dz-message {
    flex-basis: 100%;
    order: -1;
}
.csdropzone .dz-preview .dz-image {
    display: flex;
    align-items: center;
    justify-content: center;

    img {
        max-width: 100%;
    }
}
.dropzone .dz-remove {
    &:hover {
        background-color: $danger !important;
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23FFFFFF'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e");
    }
}
.dropzone .dz-edit {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 1.65rem;
    width: 1.65rem;
    white-space: nowrap;
    position: absolute;
    z-index: 999;
    background-size: 40%;
    background-color: #ffffff !important;
    box-shadow: 0 0.1rem 1rem 0.25rem rgb(0 0 0 / 5%);
    border-radius: 100%;
    top: -0.825rem;
    right: 1.175rem;
    cursor: pointer;
    &:hover {
        background-color: $warning !important;
        i {
            color: #fff;
        }
    }
    i {
        font-size: 11px;
        cursor: pointer;
    }
}

.dropzone .dz-viewimage {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 1.65rem;
    width: 1.65rem;
    white-space: nowrap;
    position: absolute;
    z-index: 999;
    background-size: 40%;
    background-color: #ffffff !important;
    box-shadow: 0 0.1rem 1rem 0.25rem rgb(0 0 0 / 5%);
    border-radius: 100%;
    top: -0.825rem;
    right: 3.175rem;
    cursor: pointer;
    &:hover {
        background-color: $primary !important;
        i {
            color: #fff;
        }
    }
    i {
        font-size: 11px;
        cursor: pointer;
    }
}

.dropzone .dz-copyimage {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 1.65rem;
    width: 1.65rem;
    white-space: nowrap;
    position: absolute;
    z-index: 999;
    background-size: 40%;
    background-color: #ffffff !important;
    box-shadow: 0 0.1rem 1rem 0.25rem rgb(0 0 0 / 5%);
    border-radius: 100%;
    top: -0.825rem;
    right: 69px;
    cursor: pointer;
    &:hover {
        background-color: $primary !important;
        i {
            color: #fff;
        }
    }
    i {
        font-size: 11px;
        cursor: pointer;
    }
}

.dropzone .dz-preview .dz-image {
    width: 180px !important;
    height: 150px !important;
}



.stoplight {
    text-align: center;
    overflow: visible;
    div {
        width: 16px;
        height: 16px;
        border-radius: 12px;
        display: inline-block;
        vertical-align: top;
        margin: 5px;
        overflow: visible;

        &.blue {
            background-color: #4285f4;
        }

        &.green {
            background-color: #41b161;
        }

        &.yellow {
            background-color: #fbc32e;
        }

        &.red {
            background-color: #eb4e43;
        }
    }

    .blue {
        -webkit-animation: stoplight-pulse 1s ease-in-out -0.5s infinite;
        -moz-animation: stoplight-pulse 1s ease-in-out -0.5s infinite;
        -ms-animation: stoplight-pulse 1s ease-in-out -0.5s infinite;
        -o-animation: stoplight-pulse 1s ease-in-out -0.5s infinite;
        animation: stoplight-pulse 1s ease-in-out -0.5s infinite;
    }

    .green {
        -webkit-animation: stoplight-pulse 1s ease-in-out -0.25s infinite;
        -moz-animation: stoplight-pulse 1s ease-in-out -0.25s infinite;
        -ms-animation: stoplight-pulse 1s ease-in-out -0.25s infinite;
        -o-animation: stoplight-pulse 1s ease-in-out -0.25s infinite;
        animation: stoplight-pulse 1s ease-in-out -0.25s infinite;
    }

    .yellow {
        -webkit-animation: stoplight-pulse 1s ease-in-out 0s infinite;
        -moz-animation: stoplight-pulse 1s ease-in-out 0s infinite;
        -ms-animation: stoplight-pulse 1s ease-in-out 0s infinite;
        -o-animation: stoplight-pulse 1s ease-in-out 0s infinite;
        animation: stoplight-pulse 1s ease-in-out 0s infinite;
    }

    .red {
        -webkit-animation: stoplight-pulse 1s ease-in-out -0.75s infinite;
        -moz-animation: stoplight-pulse 1s ease-in-out -0.75s infinite;
        -ms-animation: stoplight-pulse 1s ease-in-out -0.75s infinite;
        -o-animation: stoplight-pulse 1s ease-in-out -0.75s infinite;
        animation: stoplight-pulse 1s ease-in-out -0.75s infinite;
    }
}

.table tbody td {
    vertical-align: middle;
}

@-webkit-keyframes stoplight-pulse {
    0%,
    100%,
    30%,
    70% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.2);
    }
}
@-moz-keyframes stoplight-pulse {
    0%,
    100%,
    30%,
    70% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.2);
    }
}
@-ms-keyframes stoplight-pulse {
    0%,
    100%,
    30%,
    70% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.2);
    }
}
@-o-keyframes stoplight-pulse {
    0%,
    100%,
    30%,
    70% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.2);
    }
}
@keyframes stoplight-pulse {
    0%,
    100%,
    30%,
    70% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.2);
    }
}


.helper-bubble {
    width: 55px;
    height: 55px;
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 999;

    a {
        width: 55px;
        height: 55px;
        @if (getMode() == dark) {
               background: #24243c;
               color: #ffffff;
          } @else {
               background: #ffffff;
               color: #000000;
        }
        box-shadow: 0 0 10px 0 rgba(0, 0, 0, .1);
        border-radius: 30%;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 3px;
        flex-direction: column;
        i {
            @if (getMode() == dark) {
                color: #ffffff;
            } @else {
                color: #000000;
            }
        }
        span { font-size: 12px; }
    }
    .close-helper {
        position: absolute;
        top: -10px;
        right: -10px;
        width: 18px;
        height: 18px;
        background: #f1416c;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        cursor: pointer;
        i {
            color:#fff;
            font-size:9px
        }
    }
}