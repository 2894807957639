//
// Aside
//

// General mode(all devices)
.aside {
	display: flex;
	flex-shrink: 0;
	flex-direction: column;
	background-color: get($aside-config, bg-color);
	@include border-radius($card-border-radius);

	// Aside menu
	.aside-menu {
		.menu {
			// Link padding x
			@include menu-link-padding-x(get($aside-config, menu-padding-x));

			// Menu indention
			@include menu-link-indention(get($aside-config, menu-indention), get($aside-config, menu-padding-x));

			// Menu item
			$active-bg-color: if(isDarkMode(), $gray-200,#F3F6F9);

			.menu-item {
				// Customize menu link default state
				@include menu-link-default-state( $title-color: $gray-600, $icon-color: $gray-400, $bullet-color:$gray-400, $arrow-color:$gray-400, $bg-color: null, $all-links: true);

				// Customize menu link hover state
				@include menu-link-hover-state( $title-color: $gray-900, $icon-color: $gray-900, $bullet-color: $gray-900, $arrow-color: $gray-900, $bg-color: transparent, $all-links: true);

				// Customize menu link show state
				@include menu-link-show-state( $title-color: $gray-900, $icon-color: $gray-900, $bullet-color: $gray-900, $arrow-color: $gray-900, $bg-color: transparent, $all-links: true);

				// Customize menu link active state
				@include menu-link-active-state( $title-color: #33c366, $icon-color: #33c366, $bullet-color: #33c366, $arrow-color: #33c366, $bg-color: transparent, $all-links: true);
			}
		}
	}

	// Aside secondary panel
	.aside-secondary {
		overflow: hidden;

		// Workspace
		.aside-workspace {
			width: get($aside-config2, width, desktop) - get($aside-config2, primary-width, desktop);
			flex-shrink: 0;
		}
	}
}

// Desktop mode
@include media-breakpoint-up(lg) {
	.aside {
		position: fixed;
		//top: get($content-spacing, desktop);
		//bottom: get($content-spacing, desktop);
		//left: get($content-spacing, desktop);
		top: 0;
		bottom: 0;
		left: 0;
		z-index: get($aside-config, z-index);
		overflow: hidden;
		width: get($aside-config, width);
		@include border-radius(get($aside-config, border-radius));

		// Logo
		.aside-logo {
			display: flex;
			justify-content: space-between;
			align-items: center;
		}
	}
}

// Tablet & mobile modes
@include media-breakpoint-down(lg) {
	.aside {
		display: none;

		// Logo
		.aside-logo {
			display: none;
		}
	}
}
