//
// Media Library
//

// Base
.cropper-modal{background-color:rgba(0,0,0,0.5) !important;opacity:1}
.media-library{
    .card-header {
        background: if(isDarkMode(), #111114, $body-bg);
    }
    .card-body{
        background: $body-bg;
        .folders {
            ul{
                li{

                }
            }
        }
        .files-wrapper{
            //@media (min-width: 992px) {
            //    width: calc(100% - 325px);
            //}
            //width: calc(100% - 275px);

            .files {
                display: flex;
                flex-wrap: wrap;
                gap:10px;
            }

            .file {
                cursor: pointer;
                flex: 0 0 calc(11.11% - 9px);

                .active{
                    //&.border.border-active-success{
                    //  border-color: #50CD89 !important;
                    //}


                    .img-wrapper{
                        position: relative;
                        &::after{
                            width: 35%;
                            height: 50%;
                            position: absolute;
                            top: 0;
                            right: 0;
                            background: #0BB783;
                            color: #fff;
                            font-size: 24px;
                            content: '\f00c';
                            font-family: 'Font Awesome 5 Pro';
                            text-align: right;
                            padding-right: 8px;
                            padding-top: 8px;
                            display: block;
                            clip-path: polygon(0 0, 100% 100%, 100% 0);

                        }
                    }
                }

                .img-wrapper{
                    width: 100%;
                    //@if isDarkMode() {
                    //    background: #fff url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI0MDAiIGhlaWdodD0iNDAwIiBmaWxsLW9wYWNpdHk9Ii4xNCIgPjxyZWN0IHg9IjIwMCIgd2lkdGg9IjIwMCIgaGVpZ2h0PSIyMDAiIC8+PHJlY3QgeT0iMjAwIiB3aWR0aD0iMjAwIiBoZWlnaHQ9IjIwMCIgLz48L3N2Zz4=);
                    //} @else {
                    //    background: transparent;
                    //}

                    background: #fff url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI0MDAiIGhlaWdodD0iNDAwIiBmaWxsLW9wYWNpdHk9Ii4xNCIgPjxyZWN0IHg9IjIwMCIgd2lkdGg9IjIwMCIgaGVpZ2h0PSIyMDAiIC8+PHJlY3QgeT0iMjAwIiB3aWR0aD0iMjAwIiBoZWlnaHQ9IjIwMCIgLz48L3N2Zz4=);

                    background-size: 15px 15px;
                }

                .content{

                }
            }
        }
    }
}

#library-modal .modal-header {
    background: #F9FAFB;
}
