//
// Layout Config
//

// Root font Sizes
$root-font-size:                                    13px; // Root font size for desktop mode
$root-font-size-lg:                             	13px; // Root font size for tablet mode
$root-font-size-md:                             	12px; // Root font size for mobile mode 

// Page bg
$page-bg: 											if(isDarkMode(), #151521, #F9FAFB) !default;

// Content Spacing
$content-spacing: (
	desktop: 20px, // Padding for desktop mode // Önceki değer 50
	tablet-and-mobile: 10px // Padding for tablet and mobile modes // önceki değer 20
) !default;


// Content Spacing
$content-spacing-x: (
	desktop: 30px, // Padding for desktop mode
	tablet-and-mobile: 15px // Padding for tablet and mobile modes
) !default;

$content-spacing-y: (
desktop: 20px, // Padding for desktop mode
tablet-and-mobile: 10px // Padding for tablet and mobile modes
) !default;

// Header
$header-config: (
	// Desktop Mode
	height: (
		desktop: 70px,
		tablet-and-mobile: 60px
	),
	desktop: (
		// Default Mode
		default: (
			height: 80px
		),

		// Fixed Mode
		fixed: (
			height: 65px,
			z-index: 97,
			bg-color: $body-bg,
			box-shadow: if(isDarkMode(), none, 0px 10px 30px 0px rgba(82,63,105,0.08))
		)
	),

	// Tablet & Mobile Modes
	tablet-and-mobile: (
		// Default Mode
		default: (
			height: 60px,
		),

		// Fixed Mode
		fixed: (
			z-index: 97,
			height: 55px,
			bg-color: $body-bg,
			box-shadow: if(isDarkMode(), none, 0px 10px 30px 0px rgba(82,63,105,0.08))
		)
	)
) !default;


//$aside-config: (
//		z-index: 100, // Aside's z-index property
//		menu-indention: 0.75rem,
//		bg-color: if(isDarkMode(), #1E1E2D, #F2F3F5),
//		width: 225px, // Aside width for desktop mode
//		border-radius: 2rem
//) !default;

// Aside
$aside-config: (
	bg-color: $body-bg,
	z-index: 101,
	menu-padding-x: 7px,
	menu-indention: 0.75rem,
	width: 225px
) !default;


$aside-config2: (
	primary-width: (
		desktop: 245px,
		tablet-and-mobile: 70px
	),
	width: (
		desktop: 245px,
		tablet-and-mobile: 70px
	)
) !default;

//// Orginal Aside
//// Aside
//$aside-config: (
//	z-index: 100, // Aside's z-index property
//	menu-indention: 0.75rem,
//	bg-color: if(isDarkMode(), #1E1E2D, #F2F3F5),
//	width: 300px, // Aside width for desktop mode
//	border-radius: 2rem
//) !default;

// Sidebar

$sidebar-config: (
	z-index: 101,
	width: 400px,	
	bg-color: if(isDarkMode(), #1E1E2D, #192440),
	tabs: (
		link-bg-color: (
			default: if(isDarkMode(), darken(#1E1E2D, 4%), #0E172E),
			active: if(isDarkMode(), darken(#1E1E2D, 6%), #07122D)
		),
		link-icon-color: (
			default: #5E6278,
			active: $white
		),
	),
	dashed-border-color: if(isDarkMode(), $gray-200, #3E4A69),
	muted-color: if(isDarkMode(),$text-muted, #546182),
	muted-bg-color: if(isDarkMode(), darken(#1E1E2D, 2%), #0E172E)
) !default;

